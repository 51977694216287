import React, { useState } from "react";
import logo from "../Image/black-logo.png";
import logo2 from "../Image/logo.png";
import discord from "../Image/dicord-logo.png";
import twitter from "../Image/twitter-colored.png";
import opensea from "../Image/opensea-logo.png";

import "./Navbar.css";

const Navbar = () => {
	const [color, setColor] = useState(false);
	const [navcolor, setnavColor] = useState(false);
	const changeColor = () => {
		if (window.scrollY >= 2) {
			setColor(true);
		} else {
			setColor(false);
		}
	};
	window.addEventListener("scroll", changeColor);

	console.log(navcolor);

	return (
    <nav
      className={
        color
          ? "navbar navbar-expand-lg fixed-top scroll box-shadow py-4"
          : "navbar navbar-expand-lg fixed-top withoutScroll py-4"
      }
    >
      <div className="container nav-bar-container p-2">
        <div className="d-flex align-items-center">
          <a className="navbar-brand" href="#home">
            <img src={logo2} alt="" className="logo2 " />
          </a>
          <a className="navbar-brand" href="#home">
            <img src={logo} alt="" className="logo1" />
          </a>
        </div>
        <button
          className="navbar-toggler collapsed"
          onClick={() => setnavColor(!navcolor)}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="toggler-icon top-bar"></span>
          <span className="toggler-icon middle-bar"></span>
          <span className="toggler-icon bottom-bar"></span>
        </button>
        {navcolor ? (
          <div
            className="collapse bg-color  navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto text-center mb-lg-0 mb-3">
              <li className="nav-item  mb-lg-0 mb-3 mt-lg-0 mt-3">
                <a className="nav-link" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item mb-lg-0 mb-3">
                <a className="nav-link" href="#about">
                  ABOUT
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link" href="#waifu">
                  WAIFU
                </a>
              </li>
              <li className="nav-item mb-lg-0 mb-3">
                <a className="nav-link" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link" href="#team">
                  Team
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link" href="#faq">
                  FAQ
                </a>
              </li>
            </ul>
            <div className="icon-part   d-flex justify-content-center mb-lg-0 mt-lg-0 mb-4 mt-2">
              <div className=" instram mx-2">
                <a
                  href="https://twitter.com/AMV_Waifu_HC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="" className="img-fluid icon-img" />
                </a>
              </div>
              <div className="discord mx-2">
                <a
                  href="https://discord.gg/waifuharemclub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={discord} alt="" className="img-fluid icon-img" />
                </a>
              </div>

              <div className="twitter  mx-2">
                <a
                  href="https://opensea.io/collection/waifuharemclubnft"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={opensea} alt="" className="img-fluid icon-img" />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="collapse  navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto text-center mb-lg-0 mb-3">
              <li className="nav-item  mb-lg-0 mb-3 mt-lg-0 mt-3">
                <a className="nav-link me-1" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link mx-1" href="#about">
                  ABOUT
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link mx-1" href="#waifu">
                  WAIFU
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link mx-1" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link mx-1" href="#team">
                  Team
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a className="nav-link " href="#faq">
                  FAQ
                </a>
              </li>
              <li className="nav-item  mb-lg-0 mb-3">
                <a
                  className="nav-link "
                  href="https://staking.waifuharem.club/"
				  target="_blank"
				  rel="noreferrer"
                >
                  Staking
                </a>
              </li>
            </ul>
            <div className="icon-part ms-auto  d-flex justify-content-center mb-lg-0 mt-lg-0 mb-4 mt-2">
              <div className=" instram mx-2">
                <a
                  href="https://twitter.com/AMV_Waifu_HC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="" className="img-fluid icon-img" />
                </a>
              </div>
              <div className="discord mx-2">
                <a
                  href="https://discord.gg/waifuharemclub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={discord} alt="" className="img-fluid icon-img" />
                </a>
              </div>

              <div className="twitter  mx-2">
                <a
                  href="https://opensea.io/collection/waifuharemclubnft"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={opensea} alt="" className="img-fluid icon-img" />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
