import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import slider from "../Image/slider1.jpeg";
import slider1 from "../Image/slider2.png";
import slider2 from "../Image/slider3.png";
import slider3 from "../Image/slider4.png";
import slider4 from "../Image/slider5.png";
import slider5 from "../Image/slider6.png";

import styled from "styled-components";
import CustomizedSlider from "../Ranger/Ranger";

import "./Waifu.css";

const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	padding: 10px;
	border-radius: 50px;
	border: none;
	background-color: var(--secondary);
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
	box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const StyledRoundButton = styled.button`
	padding: 10px;
	border-radius: 100%;
	border: none;
	background-color: var(--primary);
	padding: 10px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: stretched;
	align-items: stretched;
	width: 100%;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const StyledLogo = styled.img`
	width: 200px;
	@media (min-width: 767px) {
		width: 300px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
	border: 4px dashed var(--secondary);
	background-color: var(--accent);
	border-radius: 100%;
	width: 200px;
	@media (min-width: 900px) {
		width: 250px;
	}
	@media (min-width: 1000px) {
		width: 300px;
	}
	transition: width 0.5s;
`;
export const StyledLink = styled.a`
	color: var(--secondary);
	text-decoration: none;
`;

const Waifu = () => {
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		SCAN_LINK: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		NFT_NAME: "",
		SYMBOL: "",
		MAX_SUPPLY: 1,
		WEI_COST: 0,
		DISPLAY_COST: 0,
		GAS_LIMIT: 0,
		MARKETPLACE: "",
		MARKETPLACE_LINK: "",
		SHOW_BACKGROUND: false,
	});

	const claimNFTs = (suply) => {
		let cost =
			suply < 5
				? 1000000000000000
				: suply < 10
				? 2000000000000000
				: suply < 15
				? 3000000000000000
				: suply < 20
				? 4000000000000000
				: 5000000000000000;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(BigInt(cost * mintAmount));
		let totalGasLimit = String(BigInt(gasLimit * mintAmount));
		console.log("Cost: ", totalCostWei);
		console.log("Gas limit: ", totalGasLimit);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		blockchain.smartContract.methods
			.mint(mintAmount)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once("error", (err) => {
				console.log(err);
				setFeedback("Sorry, something went wrong please try again later.");
				setClaimingNft(false);
			})
			.then((receipt) => {
				console.log(receipt);
				setFeedback(
					`WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
				);
				setClaimingNft(false);
				dispatch(fetchData(blockchain.account));
			});
	};

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > 5) {
			newMintAmount = 5;
		}
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();
	}, []);

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	return (
		<div className="waifu" id="waifu">
			<div className="container py-5">
				<div className="row py-5 align-items-center">
					<div className="col-lg-6 mb-lg-0 mb-5 col-11 mx-auto">
						<div className="waifu-title mb-4">
							MEET THE <span className="waifu-title-pink">AHEGAO </span> WAIFUS
						</div>
						<div className="waifu-details mb-4">
							<span className="waifu-details-pink">
								Each waifu is unique and only for you!
							</span>
							Not only does Your Waifu emenate class and status, it also grants
							you membership access to the Waifu Harem Club membership and the
							ETHsekai The <span className="waifu-details-pink">Ahegao</span>{" "}
							Waifu collection will be the only set with Ahegao feature!
						</div>
						<div className="list-grid ms-3">
							<div className="left-list">
								<ul>
									<li>Hair</li>
									<li>Head</li>
									<li>Eyes</li>
									<li>Face</li>
									<li>Mouth</li>
								</ul>
							</div>
							<div className="right-list">
								<ul>
									<li>Accessory</li>
									<li>Neck</li>
									<li>Top</li>
									<li>Rarity</li>
									<li>Skin</li>
								</ul>
							</div>
						</div>
					</div>

					<div
						id="carouselExampleIndicators"
						className="carousel col-lg-5 col-11 mb-lg-0 mb-5 mx-auto slide"
						data-bs-ride="carousel"
					>
						<div className="carousel-indicators">
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="0"
								className="active"
								aria-current="true"
								aria-label="Slide 1"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="1"
								aria-label="Slide 2"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="2"
								aria-label="Slide 3"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="3"
								aria-label="Slide 4"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="3"
								aria-label="Slide 4"
							></button>
						</div>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src={slider1} className="d-block w-75" alt="..." />
							</div>
							<div className="carousel-item">
								<img src={slider} className="d-block w-75" alt="..." />
							</div>
							<div className="carousel-item">
								<img src={slider2} className="d-block w-75" alt="..." />
							</div>
							<div className="carousel-item">
								<img src={slider3} className="d-block w-75" alt="..." />
							</div>
						</div>
						<button
							className="carousel-control-prev"
							type="button"
							data-bs-target="#carouselExampleIndicators"
							data-bs-slide="prev"
						>
							<span
								className="carousel-control-prev-icon"
								aria-hidden="true"
							></span>
							<span className="visually-hidden">Previous</span>
						</button>
						<button
							className="carousel-control-next"
							type="button"
							data-bs-target="#carouselExampleIndicators"
							data-bs-slide="next"
						>
							<span
								className="carousel-control-next-icon"
								aria-hidden="true"
							></span>
							<span className="visually-hidden">Next</span>
						</button>
					</div>

					<div className="col-11 mx-auto mt-5">
						<div className="ranger-label-grid py-3">
							<div className="ranger-number">0</div>
							<div className="ranger-number-last">25%</div>
							<div className="ranger-number-last">50%</div>
							<div className="ranger-number-last">75%</div>
							<div className="ranger-number-last-one">100%</div>
						</div>
						<CustomizedSlider />
						<div className="ranger-label-text-grid py-3">
							<div className="ranger-text pink-ranger">0.04ETH</div>
							<div className="ranger-text">0.06ETH</div>
							<div className="ranger-text">0.08ETH</div>
							<div className="ranger-text">0.10ETH</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Waifu;
