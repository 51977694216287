import React from "react";
import heart from "../Image/heart-icon.png";
import roadmap from "../Image/roadmap-bg-new.png";
import "./Roadmap.css";
const Roadmap = () => {
	return (
		<div className="roadmap" id="roadmap">
			<div className="py-5">
				<div className="about-title text-center py-5 mt-4 mb-5">
					ROADMAP TO ETHSEKAI
				</div>
				<div className="container wrapper">
					<div className="row">
						<div className="col-11 col-lg-12 mx-auto">
							<div className="center-line">
								<a href="#" className="scroll-down">
									<i className="fas fa-claret-up"></i>
								</a>
							</div>

							<div className="item row-2">
								<section>
									<img src={heart} alt="" className="img-fluid icon" />
									<div className="details mb-4">
										<span className="title">VIP Recruitment</span>
									</div>
									<p>
										A limited amount of Genesis Waifu NFTs will be reserved for
										Whitelist, which will start with the lowest mint price of
										0.04 ETH
									</p>
								</section>
							</div>
							<div className="item row-1">
								<section>
									<img src={heart} alt="" className="img-fluid icon" />
									<div className="details mb-4 text-lg-end">
										<span className="title ">ETHsekai Waifu Launch</span>
									</div>
									<p className="text-lg-end">
										ETHsekai Waifus with new features will be launched in the
										future. Genesis Waifu holders will be able to mint for free
										+ gas fee
									</p>
								</section>
							</div>
							<div className="item row-2">
								<section>
									<img src={heart} alt="" className="img-fluid icon" />
									<div className="details mb-4">
										<span className="title">UR Waifu giveaways</span>
									</div>
									<p>
										20 UR rarity Genesis Ahegao Waifus and more will be
										airdropped to early joiners. The UR Waifus will be the only
										Waifus in the collection strong enough to remain topless as
										well as access to juicy unlockable content
									</p>
								</section>
							</div>
							<div className="item row-1">
								<section>
									<img src={heart} alt="" className="img-fluid icon" />
									<div className="details mb-4 text-lg-end">
										<span className="title ">ETHsekai Waifu Launch</span>
									</div>
									<p className="text-lg-end">
										ETHsekai Waifus with new features will be launched in the
										future. Genesis Waifu holders will be able to mint for free
										+ gas fee
									</p>
								</section>
							</div>
							<div className="item row-2">
								<section>
									<img src={heart} alt="" className="img-fluid icon" />
									<div className="details mb-4">
										<span className="title">Vote for the Future</span>
									</div>
									<p>
										The community will regularly vote on new initiatives for the
										project’s future direction for additional Waifu utilities.
									</p>
								</section>
							</div>
							<div className="item row-1">
								<section>
									<img src={heart} alt="" className="img-fluid icon" />
									<div className="details mb-4 text-lg-end">
										<span className="title ">ETHsekai Waifu Launch</span>
									</div>
									<p className="text-lg-end">
										ETHsekai Waifus with new features will be launched in the
										future. Genesis Waifu holders will be able to mint for free
										+ gas fee
									</p>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mx-auto text-center">
				<img
					src={roadmap}
					alt=""
					className="bottom-img mx-auto text-center img-fluid"
				/>
			</div>
		</div>
	);
};

export default Roadmap;
