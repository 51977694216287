import React from "react";
import story1 from "../Image/story1.png";
import story2 from "../Image/story2.png";
import story3 from "../Image/story3.png";
import story4 from "../Image/story4.png";
import story5 from "../Image/story5.png";
import story6 from "../Image/story6.png";
import "./About.css";
const About = () => {
	return (
		<div className="about" id="about">
			<div className="container py-5">
				<div className="row">
					<div className="col-11 col-lg-12 px-200 mx-auto">
						<div className="about-title text-center mt-4 mb-3">THE STORY</div>
						<div className="about-details px-lg-5 px-0 mb-5">
							<span className="about-details-pink">
								The ETHsekai is a fantasy world that exists on the Ethereum
								Blockchain that is home to 10,000 beautiful and luscious babes ,
							</span>{" "}
							they are waiting for you to bring them along as your Waifus and
							save their world. The Waifu Harem Club is an exquisite club for
							the heroes in the ETHsekai and their Waifu companions, who will
							also be hard at work whether you’re adventuring in the ETHsekai or
							hodling. You can bring your Waifus to life in the ETHsekai, from
							giving them a unique name and background to giving them voices.
							You will also be able to view and showcase your Waifu Harem in a
							dedicated showroom. These features will be available after the
							launch.
						</div>

						<div className="grid-img mb-5">
							<img
								src={story1}
								alt=""
								className=" mx-auto story-img img-fluid"
							/>
							<img
								src={story2}
								alt=""
								className="mx-auto story-img img-fluid"
							/>
							<img
								src={story3}
								alt=""
								className=" mx-auto story-img img-fluid"
							/>
							<img
								src={story4}
								alt=""
								className=" mx-auto story-img img-fluid"
							/>
							<img
								src={story5}
								alt=""
								className=" mx-auto story-img img-fluid"
							/>
							<img
								src={story6}
								alt=""
								className=" mx-auto story-img img-fluid"
							/>
						</div>
						<div className="d-flex px-lg-5 px-0 mb-5 flex-column align-items-center">
							<div className="about-details mb-4">
								With your Waifu NFTs, you have full commercial and creative
								rights to do whatever you want with your Waifus ;) Your Waifus
								also grant you access to new Waifu Harem Club and other
								Animetaverse collections, rewards, and many more surprises to
								come!
							</div>
							<a
								href="https://www.waifuharem.club/WHC_WaifuPaper.pdf"
								target="_blank"
								rel="noopener noreferrer"
								style={{color: "white", textDecoration: "none"}}
							>	
								<button className="story-btn mb-4">Download WaifuPaper</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
