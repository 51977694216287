import React from "react";
import Navbar from "../Navbar/Navbar";
import left from "../Image/footer-image-left.png";
import right from "../Image/footer-image-right.png";
import { FaDiscord } from "react-icons/fa";
import "./Header.css";
const Header = () => {
	return (
		<div className="header" id="home">
			<Navbar />
			<div className="position-header">
				<div className="d-flex flex-column align-items-center">
					<div className="header-text text-center mb-4">
						WELCOME TO THE <span className="header-pink">WAIFU</span>{" "}
						ANIMETAVERSE <br />
						<span className="header-pink">アニメタバース</span>
					</div>

					<a
						// href="https://discord.gg/waifuharemclub"
						href="#thisIsTheMint"
						// target="_blank"
						rel="noopener noreferrer"
						style={{color: "white", textDecoration: "none"}}
					>			
						<button className="header-btn d-flex align-items-center">
							{/* <span className="icon-discord mx-3">
								<FaDiscord style={{ color: "white", fontSize: "25px" }} />
							</span> 
							<span className="btn-text">Join Discord</span>*/}
							<span className="btn-text">Claim your waifu!</span>
						</button>
					</a>
				</div>
			</div>

			<img
				src={left}
				alt=""
				className="img-fluid position-img-left d-sm-none w-50"
			/>

			<img
				src={right}
				alt=""
				className="d-sm-none img-fluid position-img-right w-50"
			/>
		</div>
	);
};

export default Header;
