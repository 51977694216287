import React from "react";
import techBullion from "../Image/Tech-Bullion-Logo.png";
import publishox from "../Image/download.png";
import nft from "../Image/nft-calendar-transparent.png";
import coin from "../Image/Coinspeaker.png";
import rarity from "../Image/logo2-white.png";

import twitter from "../Image/twitter-light.png";
import "./Team.css";
const Team = () => {
	return (
		<div className="team" id="team">
			<div className="container py-5">
				<div className="row">
					<div className="col-11 col-lg-12 mx-auto">
						<div className="seen-title text-center mt-4 mb-5">AS SEEN ON</div>
						<div className="logo-part mb-5" style={{textAlign: "center"}}>
							<a target="_blank" href="https://techbullion.com/waifu-harem-club-nfts-the-first-interactive-anime-nft-collection-in-the-metaverse/">
							<img
								src={techBullion}
								className=" story-img mx-auto img-fluid"
							/>
							</a>
							<a target="_blank" href="https://www.publish0x.com/press-releases/waifu-harem-club-nfts-the-first-interactive-anime-nft-collec-xnxdznj">
							<img
								src={publishox}
								className="mx-auto story-img img-fluid"
							/>
							</a>
							<a target="_blank" href="https://raritysniper.com/nft-drops-calendar">
							<img style={{height: 100}} 
								src={rarity} 
								className="mx-auto story-img img-fluid" />
							</a>
							<a target="_blank" href="https://nftcalendar.io/event/waifu-harem-club/">
							<img
								src={nft}
								width="100px"
								className="mx-auto story-img img-fluid"
							/>
							</a>
							<a target="_blank" href="https://www.coinspeaker.com/waifu-harem-club-nfts-the-first-interactive-anime-nft-collection-in-the-metaverse/">
							<img 
								src={coin} 
								className="mx-auto story-img img-fluid" 
							/>
							</a>
						</div>
						<div className="team-title text-center mt-4 mb-3">
							MEET THE WHC TEAM
						</div>
						<div className="team-details mb-4">
							<span className="team-details-pink">
								The creators of WHC and ETHsekai is a multinational team because
								the weaboo culture has no bounds.
							</span>{" "}
							<br />
							Although our backgrounds varies from financial services,
							management, to technology and crypto, our <br /> passion for Anime
							and Waifus are the same.
						</div>
						<div className="team-grid py-5">
							<div className="team-card p-4">
								<div className="position d-flex flex-column align-items-center">
									<div className="member-name mb-4">Hakamori</div>
									<div className="member-profession mb-4">
										Founder & Director
									</div>
									<img src={twitter} alt="" className="twitter mb-3" />

									<div className="twitter-account">
										<a
											href="https://twitter.com/hakamor1"
											target="_blank"
											rel="noopener noreferrer"
										>
											@hakamor1
										</a>
									</div>
								</div>
							</div>
							<div className="team-card1 p-4">
								<div className="position d-flex flex-column align-items-center">
									<div className="member-name mb-4">Waterman</div>
									<div className="member-profession mb-4">
										Marketing Director
									</div>
									<img src={twitter} alt="" className="twitter mb-3" />

									<div className="twitter-account">
										<a
											href="https://twitter.com/Waterman_crypto"
											target="_blank"
											rel="noopener noreferrer"
										>
											@Waterman_crypto
										</a>
									</div>
								</div>
							</div>
							<div className="team-card2 p-4">
								<div className="position d-flex flex-column align-items-center">
									<div className="member-name mb-4">Fagundes</div>
									<div className="member-profession mb-4">
										Smart contract developer
									</div>
									<img src={twitter} alt="" className="twitter mb-3" />

									<div className="twitter-account">
										<a
											href="https://twitter.com/filfagundes"
											target="_blank"
											rel="noopener noreferrer"
										>
											@filfagundes
										</a>
									</div>
								</div>
							</div>
							<div className="team-card3 p-4">
								<div className="position d-flex flex-column align-items-center">
									<div className="member-name mb-4 text-center">
										Waifu Creator Group
									</div>

									<div className="text-center group">
										Decentralized Waifu Creation Process
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Team;
