import * as React from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import "./Ranger.css";
function ValueLabelComponent(props) {
	const { children, value } = props;

	return (
		<Tooltip enterTouchDelay={0} placement="top" title={value}>
			{children}
		</Tooltip>
	);
}

ValueLabelComponent.propTypes = {
	children: PropTypes.element.isRequired,
	value: PropTypes.number.isRequired,
};

const marks = [
	{
		value: 0,
		label: "0",
	},
	{
		value: 25,
		label: "25%",
	},
	{
		value: 50,
		label: "50%",
	},
	{
		value: 75,
		label: "75%",
	},
	{
		value: 100,
		label: "100%",
	},
];

const PrettoSlider = styled(Slider)({
	color: "#141315",
	height: 22,
	"& .MuiSlider-track": {
		border: "none",
		backgroundColor: "#ec4899",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "transparent",

		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
	},
});

export default function CustomizedSlider(props) {
	const { value } = props;
	const [data, setData] = React.useState(0); 
	React.useEffect(() => {
		setData(value);
		console.log(value);
	},[props.value])

	let valuerSlider;

	let _supply = Number(data);

    if(_supply > 0 && _supply < 1500) {
        valuerSlider = 10 ;
    }
	if(_supply > 1501 && _supply < 2500) {
        valuerSlider = 25 ;
    }

	if(_supply > 2501 && _supply < 3500) {
        valuerSlider = 35 ;
    }
	if(_supply > 3501 && _supply < 5000) {
        valuerSlider = 50 ;
    }

	if(_supply > 5001 && _supply < 6500) {
        valuerSlider = 60 ;
    }
	if(_supply > 6501 && _supply < 7500) {
        valuerSlider = 75 ;
    }

	if(_supply > 7501 && _supply < 8500) {
        valuerSlider = 85 ;
    }
	if(_supply > 8501 && _supply < 9900) {
        valuerSlider = 95 ;
    }

	if(_supply > 9901 && _supply < maxSupply) {
        valuerSlider = 100 ;
    }

	return (
		<Box sx={{ width: "100%" }}>
			<PrettoSlider value={Number(valuerSlider)} />
		</Box>
	);
}
