import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
const MyAccordion = ({ element }) => {
	const { question, answer } = element;
	const [show, setShow] = useState(false);
	return (
		<div className="accordion-items mb-4">
			<h1 className="accordion-headers">
				<div
					className="faq-button p-3 d-flex justify-content-between align-items-center"
					onClick={() => setShow(!show)}
				>
					<div className="question">{question}</div>
					<div>
						{show ? (
							<div className="icon-relative">
								<AiOutlineMinus className="minus-icon" />
							</div>
						) : (
							<div className="icon-relative">
								<AiOutlinePlus className="plus-icon" />
							</div>
						)}
					</div>
				</div>
			</h1>

			{show && (
				<div className="accordion-body">
					<div className="py-5 border-faq">{answer}</div>
				</div>
			)}
		</div>
	);
};

export default MyAccordion;
