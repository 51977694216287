import React from "react";
// import pic from "../Image/sample.png";
// import slider from "../Image/mint-slide.png";
// import slider2 from "../Image/mint-slide2.jpg";
import slide1 from "../Image/slide1.jpeg";
import slide2 from "../Image/slide2.jpeg";
import slide3 from "../Image/slide3.jpeg";

// Import css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Mint = () => {
	const sliderData = [slide2, slide1, slide3];

	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<div className="waifu slider_container" id="waifu">
			<div className="container py-5">
				<div className="row py-5 align-items-start">
					<div className="col-lg-6 mb-lg-0 mb-5 col-11 mx-auto">
						{/* <img src={pic} width='400px' /> */}
						<div className="col-lg-7 col-11 mb-lg-0 mx-auto">
							<Slider {...settings}>
								{sliderData.map((content, i) => {
									return (
										<div key={i}>
											<img className="w-100" src={content} alt="" />
										</div>
									);
								})}
							</Slider>
						</div>
					</div>
					<div className="col-lg-6 col-11 mb-lg-0 mx-auto">
						<div className="waifu-title mb-4">
							FREE MERCH FOR <span className="waifu-title-pink"> MINTING </span>{" "}
							2 & 3 Waifu Harem Club NFTs
						</div>

						<div className="singleListGrid ms-5 mx-5">
							<div className="singleList">
								<ul>
									<li style={{color: "white", fontSize: 22, marginBottom: 15}}> 
										Unique Waifu {"   "}
										<span className="waifu-title-pink">Heat Changing Mug</span>
									</li>
									<li style={{color: "white", fontSize: 22, marginBottom: 15}}>
										Unique Waifu{"   "}
										<span className="waifu-title-pink">Oppai Mousepad</span>
									</li>
									<li style={{color: "white", fontSize: 22, marginBottom: 25}}>
										Limited Edition{"   "}
										<span className="waifu-title-pink">
											Waifu Harem Club Poster
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="waifu-details py-2">
							<div>If you mint 2 you can pick 1 of the 3 products above.</div>
							<br />
							<div>
								{" "}
								If you mint 3 you will get all the freebies mentioned above.
							</div>
						</div>
						<div style={{color: "white", fontSize: 18, marginTop: 10 }} className="mintLast">
							We will start printing your Waifus onto the products once the
							Waifus are officially revealed ❤️
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Mint;
