import React from "react";
import discord from "../Image/dicord-logo.png";
import twitter from "../Image/twitter-colored.png";
import opensea from "../Image/opensea-logo.png";
import "./Footer.css";
const Footer = () => {
	return (
		<div className="footer">
			<div className="container pt-5">
				<div className="row">
					<div className="col-11  col-lg-12 mx-auto">
						<div className="about-title text-center mt-4 mb-2">
							WAIFU HAREM CLUB
						</div>
						<div className="title-pink text-center mb-5">
							ワイフ ハーレム クラブ
						</div>
						<div className="contact-us text-center mb-3">CONTACT US</div>
						<div className="icon-part d-flex justify-content-center  mb-5 mt-2">
							<div className="discord mx-2">
								<a
									href="https://discord.gg/waifuharemclub"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={discord} alt="" className="img-fluid" />
								</a>
							</div>
							<div className=" instram mx-2">
								<a
									href="https://twitter.com/AMV_Waifu_HC"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={twitter} alt="" className="img-fluid" />
								</a>
							</div>
							<div className="twitter  mx-2">
								<a href="#" target="_blank" rel="noopener noreferrer">
									<img href="https://opensea.io/collection/waifuharemclubnft" src={opensea} alt="" className="img-fluid" />
								</a>
							</div>
						</div>
						<div className="copy-right py-4 mt-3 border-top text-center">
							Copyright © 2022 Waifu Harem Club
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
