export const questions = [
	{
		id: 1,
		question: "How many NFTs are there?",
		answer:
			"There will be 10,000 unique Genesis Ahegao Waifus in this collection",
	},
	{
		id: 2,
		question: "What is so special about the Waifu Harem Club?",
		answer:
			"You will be able to bring your Waifus to life by customising their names, backgrounds, voices, and more. Furthermore, you can stake them for additional utilities such as virtual showroom and crypto mining profits. Other utilities such as gaming will be revealed in the future",
	},
	{
		id: 3,
		question: "Will there be new Waifus in the future?",
		answer:
			"Yes, aside from the ETHsekai Waifus, which will be available to members with Genesis Ahegao Waifus for free + gas, new editions of Waifus will be available in the future. There is no plan for future Waifus to have the Ahegao feature",
	},
	{
		id: 4,
		question: "What is the rarity trait?",
		answer:
			"The rarity trait is used to determine the tier of the Waifus, which is shown as the background. There are 6 tiers, which are Common (C), Uncommon (UC), Rare (R), Super Rare (SR), Super Super Rare (SSR), and Ultra Rare (UR). Pre-generated 1/1 Waifus from collabs and events will be UR rarity by default.",
	},
	{
		id: 5,
		question: "How can I get whitelist?",
		answer:
			"We will host regular giveaway raffles and contests for Whitelist and Main Character (OG) roles. Look out for Discord announcements and Twitter posts for how to participate!",
	},
	{
		id: 6,
		question: "Why is the team anonymous?",
		answer:
			"The core principle of the ETHsekai is to be completely immersed in the Anime world so we want to distance our real-world identities from the ones we will create together.",
	},
];
