import React, { useState } from "react";
import left from "../Image/faq-left-new.png";
import right from "../Image/faq-right-new.png";
import { questions } from "./api";
import "./faq.css";
import MyAccordion from "./MyAccordion";
const Faq = () => {
	const [data, setData] = useState(questions);
	return (
		<div className="faq pt-5 pb-5" id="faq">
			<div className="container faq-container pb-5">
				<div className="title-faq text-center pt-5" id="faq">
					FREQUENTLY ASKED QUESTIONS
				</div>
				<div className="col-11 col-lg-12 mx-auto" data-aos="fade-up">
					<div className="dark-part">
						<div className="accordion mt-5" id="accordionExample">
							<div className="col-11 mx-auto">
								{data.map((curElem) => {
									return <MyAccordion key={curElem.id} element={curElem} />;
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<img
				src={left}
				alt=""
				className="faq-left-position  d-lg-inline-block d-none img-fluid"
			/>
			<img
				src={right}
				alt=""
				className="faq-right-position d-lg-inline-block d-none img-fluid"
			/>
		</div>
	);
};

export default Faq;
