import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import whitelistusers from "./arrWL.json";
import Navbar from "./Component/Navbar/Navbar";
import Header from "./Component/Header/Header";
import About from "./Component/About/About";
import Waifu from "./Component/Waifu/Waifu";
import slider from "../src/Component/Image/slider1.jpeg";
import slider1 from "../src/Component/Image/slider2.png";
import slider2 from "../src/Component/Image/slider3.png";
import slider3 from "../src/Component/Image/slider4.png";
import slider4 from "../src/Component/Image/slider5.png";
import slider5 from "../src/Component/Image/slider6.png";
import "./App.css";
import Team from "./Component/Team/Team";
import Faq from "./Component/Faq/Faq";
import Roadmap from "./Component/Roadmap/Roadmap";
import Footer from "./Component/Footer/Footer";
import CustomizedSlider from "./Component/Ranger/Ranger";
import Mint from "./Component/Mint/Mint";
const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	padding: 10px;
	border-radius: 5px;
	border: none;
	background-color: var(--secondary);
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
	
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const StyledRoundButton = styled.button`
	padding: 10px;
	border-radius: 15%;
	border: none;
	background-color: var(--primary);
	padding: 10px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: stretched;
	align-items: stretched;
	width: 100%;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const StyledLogo = styled.img`
	width: 200px;
	@media (min-width: 767px) {
		width: 300px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
	border: 4px dashed var(--secondary);
	background-color: var(--accent);
	border-radius: 100%;
	width: 200px;
	@media (min-width: 900px) {
		width: 250px;
	}
	@media (min-width: 1000px) {
		width: 300px;
	}
	transition: width 0.5s;
`;

export const StyledLink = styled.a`
	color: #ec4899;
	text-decoration: none;
	:hover {
		color: #ec4899;
	}
`;

function App() {
	
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		SCAN_LINK: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		NFT_NAME: "",
		SYMBOL: "",
		MAX_SUPPLY: 1,
		WEI_COST: 0,
		DISPLAY_COST: 0,
		GAS_LIMIT: 0,
		MARKETPLACE: "",
		MARKETPLACE_LINK: "",
		SHOW_BACKGROUND: false,
	});

	const claimNFTs = (suply) => {
		let cost =
		suply < 2500
			? 40000000000000000
			: suply < 5000
			? 60000000000000000
			: suply < 75000
			? 80000000000000000
			: 100000000000000000;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(BigInt(cost * mintAmount));
		let totalGasLimit = String(BigInt(gasLimit * mintAmount));
		console.log("Cost: ", totalCostWei);
		console.log("Gas limit: ", totalGasLimit);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		blockchain.smartContract.methods
			.mint(mintAmount)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once("error", (err) => {
				console.log(err);
				setFeedback("Sorry, something went wrong please try again later.");
				setClaimingNft(false);
			})
			.then((receipt) => {
				console.log(receipt);
				setFeedback(
					`WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
				);
				setClaimingNft(false);
				dispatch(fetchData(blockchain.account));
			});
		
	};

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > 15) {
			newMintAmount = 15;
		}
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();
	}, []);

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	return (
		<div className="app">
			<Header />
			<About />
			<div className="waifu" id="waifu">
				<div className="container py-5">
					<div className="row py-5 align-items-center">
						<div className="col-lg-6 mb-lg-0 mb-5 col-11 mx-auto">
							<div className="waifu-title mb-4">
								MEET THE <span className="waifu-title-pink">AHEGAO </span>{" "}
								WAIFUS
							</div>
							<div className="waifu-details mb-4">
								<span className="waifu-details-pink">
									Each waifu is unique and only for you!
								</span>{" "}
								Not only does Your Waifu emenate class and status, it also
								grants you membership access to the Waifu Harem Club membership
								and the ETHsekai The{" "}
								<span className="waifu-details-pink">Ahegao</span> Waifu
								collection will be the only set with Ahegao feature!
							</div>
							<div className="list-grid ms-3">
								<div className="left-list">
									<ul>
										<li>Hair</li>
										<li>Head</li>
										<li>Eyes</li>
										<li>Face</li>
										<li>Mouth</li>
									</ul>
								</div>
								<div className="right-list">
									<ul>
										<li>Accessory</li>
										<li>Neck</li>
										<li>Top</li>
										<li>Rarity</li>
										<li>Skin</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-11 mb-lg-0 mx-auto">
							<div
								id="carouselExampleIndicators"
								className="carousel slide"
								data-bs-ride="carousel"
							>
								<div className="carousel-indicators">
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="0"
										className="active"
										aria-current="true"
										aria-label="Slide 1"
									></button>
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="1"
										aria-label="Slide 2"
									></button>
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="2"
										aria-label="Slide 3"
									></button>
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="3"
										aria-label="Slide 4"
									></button>
									<button
										type="button"
										data-bs-target="#carouselExampleIndicators"
										data-bs-slide-to="3"
										aria-label="Slide 4"
									></button>
								</div>
								
								<div className="carousel-inner" >
									<div className="carousel-item active">
										<img src={slider1} className="d-block w-100" alt="..." />
									</div>
									<div className="carousel-item">
										<img src={slider} className="d-block w-100" alt="..." />
									</div>
									<div className="carousel-item">
										<img src={slider2} className="d-block w-100" alt="..." />
									</div>
									<div className="carousel-item">
										<img src={slider3} className="d-block w-100" alt="..." />
									</div>
								</div>
								
								<button
									className="carousel-control-prev"
									type="button"
									data-bs-target="#carouselExampleIndicators"
									data-bs-slide="prev"
								>
									<span
										className="carousel-control-prev-icon"
										aria-hidden="true"
									></span>
									<span className="visually-hidden">Previous</span>
								</button>
								
								<button
									className="carousel-control-next"
									type="button"
									data-bs-target="#carouselExampleIndicators"
									data-bs-slide="next"
								>
									<span
										className="carousel-control-next-icon"
										aria-hidden="true"
									></span>
									<span className="visually-hidden">Next</span>
								</button>
							</div>
						</div>
						<div id="thisIsTheMint"></div>
						{/* <div className="col-11 mx-auto mt-5" >
							<div className="ranger-label-grid py-3">
								<div className="ranger-number">0</div>
								<div className="ranger-number-last">25%</div>
								<div className="ranger-number-last">50%</div>
								<div className="ranger-number-last">75%</div>
								<div className="ranger-number-last-one">100%</div>
							</div>
							<CustomizedSlider value={data.totalSupply==0?1:data.totalSupply} />
							<div className="ranger-label-text-grid py-3">
								<div className={`ranger-text ${data.totalSupply>0 && data.totalSupply<=2500?"pink-ranger":""}`}>0.04ETH</div>
								<div className={`ranger-text ${data.totalSupply>2500 && data.totalSupply<=5000?"pink-ranger":""}`}>0.06ETH</div>
								<div className={`ranger-text ${data.totalSupply>5000 && data.totalSupply<=7500?"pink-ranger":""}`}>0.08ETH</div>
								<div className={`ranger-text ${data.totalSupply>7500 && data.totalSupply<=10000?"pink-ranger":""}`}>0.10ETH</div>
							</div>
						</div> */}
					</div>
					<s.Container
						flex={2}
						jc={"center"}
						ai={"center"}
						style={{
							padding: 24,
							borderRadius: 24,
						}}
					>
					 {/* <s.TextTitle
							style={{
								textAlign: "center",
								fontSize: 50,
								fontWeight: "bold",
								color: "var(--accent-text)",
							}}
						>
							{data.totalSupply} / {CONFIG.MAX_SUPPLY}
						</s.TextTitle>  */}
						<s.TextDescription
							style={{
								textAlign: "center",
								color: "#ec4899",
							}} 
						>
							<StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
								View Contract
							</StyledLink>
						</s.TextDescription>
						<s.SpacerSmall />
						{Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
							<>
								<s.TextTitle
									style={{ textAlign: "center", color: "var(--accent-text)" }}
								>
									The sale has ended.
								</s.TextTitle>
								<s.TextDescription
									style={{ textAlign: "center", color: "var(--accent-text)" }}
								>
									You can still find {CONFIG.NFT_NAME} on
								</s.TextDescription>
								<s.SpacerSmall />
								<StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
									{CONFIG.MARKETPLACE}
								</StyledLink>
							</>
						) : (
							<>
								{blockchain.account === "" ||
								blockchain.smartContract === null ? (
									<s.Container ai={"center"} jc={"center"}>
										<s.TextDescription
											style={{
												textAlign: "center",
												color: "#ec4899",
											}}
										>
											Connect to the {CONFIG.NETWORK.NAME} network
										</s.TextDescription>
										<s.SpacerSmall />
										<button
											className="story-btn"
											onClick={(e) => {
												e.preventDefault();
												dispatch(connect());
												getData();
											}}
										>
											CONNECT
										</button>
										{blockchain.errorMsg !== "" ? (
											<>
												<s.SpacerSmall />
												<s.TextDescription
													style={{
														textAlign: "center",
														color: "var(--accent-text)",
													}}
												>
													{blockchain.errorMsg}
												</s.TextDescription>
											</>
										) : null}
									</s.Container>
								) : (
									<>
										<s.TextDescription
											style={{
												textAlign: "center",
												color: "var(--secondary)",
												fontSize: 24,
											}}
										>
											{feedback}
										</s.TextDescription>
										<s.SpacerMedium />
										<s.Container ai={"center"} jc={"center"} fd={"row"}>
											<StyledRoundButton
												style={{ lineHeight: 0.4 }}
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													decrementMintAmount();
												}}
											>
												-
											</StyledRoundButton>
											<s.SpacerMedium />
											<s.TextDescription
												style={{
													textAlign: "center",
													color: "var(--accent-text)",
												}}
											>
												{mintAmount}
											</s.TextDescription>
											<s.SpacerMedium />
											<StyledRoundButton
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													incrementMintAmount();
												}}
											>
												+
											</StyledRoundButton>
										</s.Container>
										<s.SpacerSmall />
										<s.Container ai={"center"} jc={"center"} fd={"row"}>
											<StyledButton
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													claimNFTs(Number(data.totalSupply));
													getData();
												}}
											>
												{claimingNft ? "BUSY" : "BUY"}
											</StyledButton>
										</s.Container>
									</>
								)}
							</>
						)}
					</s.Container>
				</div>
			</div>
			<Mint />
			<Roadmap />
			<Team />
			<Faq />
			<Footer />
		</div>
	);
}

export default App;
